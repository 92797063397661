import type { MetaObject } from 'nuxt/schema'
import type {
	SEOmatic,
	SEOmaticLink,
	SEOmaticMeta,
	SEOmaticSchema,
	SEOmaticScript,
	SEOmaticTitle,
} from '@/graphql/seomatic'

export function useSEOmatic(data?: SEOmatic) {
	if (!data) return

	const {
		metaJsonLdContainer,
		metaLinkContainer,
		metaScriptContainer,
		metaTagContainer,
		metaTitleContainer,
	} = data

	useHead({
		link: getLinks(metaLinkContainer),
		meta: getMetaTags(metaTagContainer),
		noscript: getNoScripts(metaScriptContainer),
		script: [
			...getSchema(metaJsonLdContainer),
			...getScripts(metaScriptContainer),
		],
		title: getTitle(metaTitleContainer),
	})
}

function getLinks(str?: string) {
	return parse<SEOmaticLink>(str).map((value) => {
		const link: Record<string, any> = {
			id: getId(value.rel, value.hreflang),
		}

		if (value.href) link.href = value.href
		if (value.hreflang) link.hreflang = value.hreflang
		if (value.rel) link.rel = value.rel
		if (value.type) link.type = value.type

		return link
	})
}

function getMetaTags(str?: string) {
	return parse<SEOmaticMeta>(str).map((value) => {
		const meta: Record<string, any> = {
			content: value.content || '',
			name: value.name || value.property || '',
		}

		meta.id = meta.name.includes('og:locale')
			? getId(meta.name, meta.content)
			: getId(meta.name)

		return meta
	})
}

function getSchema(str?: string) {
	return parse<SEOmaticSchema>(str).map((value) => {
		const schema: Record<string, any> = {
			children: JSON.stringify(value),
			id: getId(value['@type'], value['@id']?.match(/#(.*)$/)[1]),
			tagPosition: 'bodyClose',
			type: 'application/ld+json',
		}

		return schema
	})
}

function getScripts(str?: string) {
	return parse<SEOmaticScript>(str).map((value) => {
		const script: Record<string, any> = {
			children: value.script || '',
			tagPosition: 'head',
		}

		return script
	})
}

function getNoScripts(str?: string): MetaObject['noscript'] {
	return parse<SEOmaticScript>(str).map((value) => {
		const noscript: Record<string, any> = {
			children: value.bodyScript?.replace(/<\/?noscript>/g, '') || '',
			tagPosition: 'bodyOpen',
		}

		return noscript
	})
}

function getTitle(str?: string) {
	const titles = parse<SEOmaticTitle>(str)
	return titles[0]?.title || 'Te Pari Products'
}

function parse<T>(str?: string) {
	const json = str ? JSON.parse(str) : {}
	return Object.entries(json).flatMap(([key, value]) => value) as T[]
}

function getId(...params: any[]) {
	return `seomatic:${params
		.filter((v) => v && ['string', 'number'].includes(typeof v))
		.join(':')}`.toLowerCase()
}
